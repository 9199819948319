import { useLocation } from 'react-router-dom';
/**
 * Normalizes the tracking ID from the URL query parameters.
 * @returns The tracking ID from the URL query parameters.
 */
export const useTrackingId = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  return (
    searchParams.get('tracking_id') ||
    searchParams.get('trackingId') ||
    searchParams.get('tok')
  );
};
